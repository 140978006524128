<template>
  <v-app>
    <div class="login-frame">
      <v-overlay :value="$store.state.overlay.show" :absolute="false">
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>

      <div class="login-form">
        <router-link to="/" class="links">
          <v-img max-height="64" max-width="172" class="logo"
                 v-bind:src="require('@/assets/images/Leita_blue.png')">
          </v-img>
        </router-link>

        <validation-observer
            ref="observer"
            v-slot="{ invalid }"
            @change="changeColorByObserver()"

        >
          <v-form
              ref="form"
              @submit.prevent="setNewPassword($data)"
          >


            <label for="password" style="padding-bottom: 5px;">Password*</label>
            <validation-provider
                v-slot="{ errors }"
                name="password"
                rules="required"
            >
              <v-text-field
                  v-model="password"
                  placeholder="******"
                  :type="'password'"
                  :error-messages="errors"
                  class="v-text-field-custom"
                  tile
                  solo
              ></v-text-field>
            </validation-provider>

            <label for="repeat_password" style="padding-bottom: 5px;">Repeat Password*</label>
            <validation-provider
                v-slot="{ errors }"
                name="repeat_password"
                rules="required|confirmed:password"
            >
              <v-text-field
                  v-model="repeat_password"
                  :error-messages="errors"
                  class="v-text-field-custom"
                  tile
                  solo
                  :type="'password'"

                  placeholder="******"
              ></v-text-field>
            </validation-provider>

            <v-col style="padding: 0px; padding-bottom: 20px;">
              <v-btn
                  tile
                  :color="submitDisableButtonColor"
                  style="width: 100%;"
                  x-large
                  :disabled="invalid"
                  type="submit"
              >
                <span class="login-button-text"> Set New Password</span>


              </v-btn>
            </v-col>


          </v-form>
        </validation-observer>

      </div>
    </div>
    <Footer></Footer>
  </v-app>
</template>

<script>

import Footer from "@/components/home/Footer";
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {required, confirmed} from 'vee-validate/dist/rules';
import LoginSubmitButtonMixin from "@/mixins/LoginSubmitButtonMixin";

// Add the required rule
extend('required', {
  ...required,
  message: 'This field is required'
});

extend('confirmed', {
  ...confirmed,
  message: 'Passwords do not match'
});


export default {
  name: "Register",
  mixins: [LoginSubmitButtonMixin],
  components: {
    Footer,
    ValidationObserver,
    ValidationProvider
  },
  data: () => ({
    password: '',
    repeat_password: '',
    token: ''
  }),
  mounted() {
    if (typeof this.$route.params.token == 'undefined') {
      this.$router.push('/');
    }
    this.token = this.$route.params.token;
  },
  methods: {
    setNewPassword(payload) {
      if (this.$refs.observer.validate()) {
        this.$store.dispatch('overlay/show');
        this.$store.dispatch('authentication/setNewPassword', payload).then(
            response => {
              if (response.result) {
                this.$notify({
                  type: 'success',
                  group: 'leita',
                  duration: 10000,
                  title: 'Password set successfully',
                  text: 'You can login!'
                });
                this.$router.push('login');

              }
            },
            error => {

              if (error.response.status === 401) {
                this.$refs.observer.setErrors(error.response.data.validation);
              } else {
                this.$notify({
                  type: 'error',
                  group: 'leita',
                  duration: 10000,
                  title: 'An error has occurred',
                  text: 'Refresh the page and try again!'
                });
              }

            }
        );
      }
    }
  }
}
</script>

<style scoped src="@/assets/css/login.css">
</style>
